/* stylelint-disable custom-property-empty-line-before */
@use 'mixins/responsive';

:root,
.theme {
    --text-font-family: var(--font-family-lato);
    --text-line-height: 1.6;

    --heading-font-family: var(--font-family-oswald);
    --heading-line-height: 1.2;

    --premium-heading-font-family: var(--font-family-yrsa);
    --premium-heading-font-weight: 500;
    --premium-heading-line-height: 1.5;

    /* Font Weights */
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 900;

    --heading-large-font-size: 2.5em;
    --heading-large-color: var(--titleColor);
    --heading-large-font-family: var(--heading-font-family);
    --heading-large-font-weight: var(--font-weight-bold);
    --heading-large-line-height: var(--heading-line-height);

    --heading-medium-font-size: 1.75em;
    --heading-medium-color: var(--titleColor);
    --heading-medium-font-family: var(--heading-font-family);
    --heading-medium-font-weight: var(--font-weight-bold);
    --heading-medium-line-height: var(--heading-line-height);

    --heading-small-font-size: 1.125em;
    --heading-small-color: var(--titleColor);
    --heading-small-font-family: var(--heading-font-family);
    --heading-small-font-weight: var(--font-weight-bold);
    --heading-small-line-height: var(--heading-line-height);

    --heading-x-small-font-size: 0.875em;
    --heading-x-small-color: var(--lighterTextColor);
    --heading-x-small-font-family: var(--heading-font-family);
    --heading-x-small-font-weight: var(--font-weight-bold);
    --heading-x-small-line-height: var(--heading-line-height);
    --heading-x-small-text-transform: uppercase;

    --premium-heading-large-font-size: 3em;
    --premium-heading-large-color: var(--titleColor);
    --premium-heading-large-font-family: var(--premium-heading-font-family);
    --premium-heading-large-font-weight: var(--font-weight-medium);
    --premium-heading-large-line-height: 0.925;

    --premium-heading-medium-font-size: 1.75em;
    --premium-heading-medium-color: var(--titleColor);
    --premium-heading-medium-font-family: var(--premium-heading-font-family);
    --premium-heading-medium-font-weight: var(--font-weight-medium);
    --premium-heading-medium-line-height: 0.875;

    --article-heading-large-font-size: 2.5em;
    --article-heading-large-color: var(--titleColor);
    --article-heading-large-font-family: var(--heading-font-family);
    --article-heading-large-font-weight: var(--font-weight-bold);
    --article-heading-large-line-height: 1em;

    --article-heading-medium-font-size: 1.5em;
    --article-heading-medium-color: var(--titleColor);
    --article-heading-medium-font-family: var(--heading-font-family);
    --article-heading-medium-font-weight: var(--font-weight-bold);
    --article-heading-medium-line-height: 1em;

    --article-heading-small-font-size: 1.125em;
    --article-heading-small-color: var(--titleColor);
    --article-heading-small-font-family: var(--heading-font-family);
    --article-heading-small-font-weight: var(--font-weight-bold);
    --article-heading-small-line-height: 1.333em;

    --paragraph-large-font-size: 1.125em;
    --paragraph-large-color: var(--textColor);
    --paragraph-large-font-family: var(--text-font-family);
    --paragraph-large-font-weight: var(--font-weight-semi-bold);
    --paragraph-large-line-height: var(--text-line-height);

    --paragraph-medium-font-size: 1em;
    --paragraph-medium-color: var(--textColor);
    --paragraph-medium-font-family: var(--text-font-family);
    --paragraph-medium-font-weight: var(--font-weight-regular);
    --paragraph-medium-line-height: var(--text-line-height);

    --caption-medium-font-size: 0.875em;
    --caption-medium-color: var(--textColor);
    --caption-medium-font-family: var(--text-font-family);
    --caption-medium-font-weight: var(--font-weight-bold);
    --caption-medium-line-height: 1.285;
    --caption-medium-text-transform: uppercase;

    --caption-small-font-size: 0.75em;
    --caption-small-color: var(--textColor);
    --caption-small-font-family: var(--text-font-family);
    --caption-small-font-weight: var(--font-weight-bold);
    --caption-small-line-height: 1.5;
    --caption-small-text-transform: uppercase;

    --tag-large-font-size: 0.875em;
    --tag-large-color: var(--textColor);
    --tag-large-font-family: var(--heading-font-family);
    --tag-large-font-weight: var(--font-weight-semi-bold);
    --tag-large-line-height: 1.285;
    --tag-large-letter-spacing: 0.02em;
    --tag-large-text-transform: uppercase;
    --tag-large-border-radius: var(--standardRadius);

    --tag-medium-font-size: 0.75em;
    --tag-medium-color: var(--lighterTextColor);
    --tag-medium-font-family: var(--heading-font-family);
    --tag-medium-font-weight: var(--font-weight-semi-bold);
    --tag-medium-line-height: 2rem;
    --tag-medium-letter-spacing: 0.02em;
    --tag-medium-text-transform: uppercase;
    --tag-medium-border-radius: 1rem;

    --tag-small-font-size: 0.75em;
    --tag-small-color: var(--textColor);
    --tag-small-font-family: var(--heading-font-family);
    --tag-small-font-weight: var(--font-weight-semi-bold);
    --tag-small-line-height: 1.5;
    --tag-small-letter-spacing: 0.02em;
    --tag-small-text-transform: uppercase;
    --tag-small-border-radius: var(--standardRadius);

    --link-medium-font-size: 1em;
    --link-medium-color: var(--textColor);
    --link-medium-font-family: var(--text-font-family);
    --link-medium-font-weight: var(--font-weight-bold);
    --link-medium-line-height: 1.5;

    --link-small-font-size: 0.875em;
    --link-small-color: var(--textColor);
    --link-small-font-family: var(--text-font-family);
    --link-small-font-weight: var(--font-weight-bold);
    --link-small-line-height: 1.4;

    --premium-link-list-heading-font-size: 1.25em;
    --premium-link-list-heading-font-family: var(--premium-heading-font-family);
    --premium-link-list-heading-font-weight: var(--font-weight-medium);
    --premium-link-list-heading-line-height: 1;

    --link-list-font-size: 1em;
    --link-list-color: var(--textColor);
    --link-list-font-family: var(--text-font-family);
    --link-list-font-weight: var(--font-weight-bold);
    --link-list-line-height: 1.25;

    @include responsive.responsive(m, below) {
        --heading-large-font-size: 1.75em;
        --article-heading-large-font-size: 1.75em;
    }
}

.theme-vp,
.theme-vpbe,
.theme-vn {
    --heading-large-font-size: 2.25em;

    --heading-small-font-size: 1.25em;
    --heading-small-font-family: var(--text-font-family);

    --heading-x-small-font-weight: var(--font-weight-regular);
    --heading-x-small-letter-spacing: 0.08em;

    --article-heading-large-font-size: 3em;
    --article-heading-large-line-height: var(--heading-line-height);

    --article-heading-medium-font-size: 1.25em;
    --article-heading-medium-line-height: var(--heading-line-height);
    --article-heading-medium-font-family: var(--text-font-family);

    --article-heading-small-font-size: 1em;
    --article-heading-small-line-height: var(--heading-line-height);
    --article-heading-small-font-family: var(--text-font-family);

    --paragraph-large-font-size: 1em;

    --caption-medium-text-transform: initial;
    --caption-medium-letter-spacing: 0.02em;
    --caption-small-font-size: 0.875em;
    --caption-small-text-transform: initial;
    --caption-small-letter-spacing: 0.02em;

    @include responsive.responsive(m, below) {
        --heading-large-font-size: 1.75em;
        --heading-medium-font-size: 1.5em;
        --article-heading-large-font-size: 1.75em;
        --article-heading-medium-font-size: 1.125em;
    }
}

.theme-br {
    --text-font-family: var(--font-family-open-sans);
    --heading-font-family: var(--font-family-lexia);

    --heading-x-small-color: var(--mainColor);
    --heading-x-small-font-family: var(--text-font-family);

    --tag-small-letter-spacing: initial;
    --tag-small-text-transform: initial;
    --tag-small-font-family: var(--text-font-family);

    --tag-medium-letter-spacing: initial;
    --tag-medium-text-transform: initial;
    --tag-medium-font-family: var(--text-font-family);

    --tag-large-letter-spacing: initial;
    --tag-large-text-transform: initial;
    --tag-large-font-family: var(--text-font-family);

    --link-list-font-family: var(--heading-font-family);
}

.theme-vi {
    --text-font-family: var(--font-family-open-sans);
    --heading-font-family: var(--font-family-open-sans);

    --heading-small-font-size: 1.125em;

    --heading-x-small-text-transform: none;
    --heading-x-small-font-weight: var(--font-weight-semi-bold);

    --caption-small-text-transform: initial;
    --caption-small-font-weight: var(--font-weight-semi-bold);
    --caption-medium-text-transform: initial;
    --caption-medium-font-weight: var(--font-weight-semi-bold);

    --tag-small-font-size: 0.75em;
    --tag-small-font-weight: var(--font-weight-bold);
    --tag-small-letter-spacing: initial;
    --tag-small-text-transform: initial;

    --tag-medium-font-size: 0.75em;
    --tag-medium-font-weight: var(--font-weight-bold);
    --tag-medium-letter-spacing: initial;
    --tag-medium-text-transform: initial;

    --tag-large-letter-spacing: initial;
    --tag-large-font-weight: var(--font-weight-bold);
}

.theme-gp {
    --text-font-family: var(--font-family-titillium);
    --heading-font-family: var(--font-family-titillium);

    --heading-x-small-letter-spacing: 1px;

    --caption-small-font-weight: var(--font-weight-semi-bold);
    --caption-medium-font-weight: var(--font-weight-semi-bold);

    --tag-large-letter-spacing: 0.4px;
    --tag-large-font-weight: var(--font-weight-bold);

    --tag-small-letter-spacing: initial;
    --tag-small-font-weight: var(--font-weight-bold);

    --link-medium-letter-spacing: 0.4px;
    --link-medium-text-transform: uppercase;
}

.theme-admin {
    --text-font-family: var(--font-family-poppins);
    --heading-font-family: var(--font-family-poppins);

    --heading-large-font-size: 1.1em;

    --heading-medium-font-size: 1.1em;

    --heading-x-small-text-transform: initial;
    --heading-x-small-font-weight: var(--font-weight-regular);
}

.theme-bi {
    --text-font-family: var(--font-family-lora);
    --text-line-height: 1.625;

    --heading-font-family: var(--font-family-teko);

    --heading-large-font-family: var(--font-family-velo-serif-display);
    --heading-large-font-size: 2em;
    --heading-large-line-height: 1.25;

    --heading-medium-font-weight: var(--font-weight-regular);
    --heading-medium-text-transform: uppercase;
    --heading-medium-line-height: 1.143;

    --heading-small-font-family: var(--font-family-lexia);
    --heading-small-line-height: 1.333;

    --heading-x-small-font-family: var(--font-family-domine);
    --heading-x-small-line-height: 1.285;
    --heading-x-small-letter-spacing: 0.143em;

    --article-heading-large-font-family: var(--font-family-velo-serif-display);

    --article-heading-medium-font-weight: var(--font-weight-regular);
    --article-heading-medium-font-size: 2.25em;
    --article-heading-medium-line-height: 1.111;

    --article-heading-small-font-size: 1.5em;
    --article-heading-small-line-height: 1;

    --paragraph-large-font-family: var(--font-family-teko);
    --paragraph-large-font-weight: var(--font-weight-medium);
    --paragraph-large-font-size: 1.375em;
    --paragraph-large-line-height: 1.272;

    --caption-medium-font-family: var(--font-family-domine);
    --caption-medium-font-weight: var(--font-weight-regular);
    --caption-medium-text-transform: initial;

    --caption-small-font-family: var(--font-family-domine);
    --caption-small-font-weight: var(--font-weight-regular);
    --caption-small-text-transform: initial;

    --tag-medium-font-family: var(--font-family-domine);
    --tag-medium-font-size: 0.875em;
    --tag-medium-font-weight: var(--font-weight-bold);
    --tag-medium-letter-spacing: initial;
    --tag-medium-line-height: 1.285;
    --tag-medium-text-transform: initial;

    --tag-small-font-family: var(--font-family-domine);
    --tag-small-font-weight: var(--font-weight-bold);
    --tag-small-letter-spacing: initial;
    --tag-small-text-transform: initial;

    --link-medium-font-family: var(--font-family-teko);
    --link-medium-text-transform: uppercase;

    --link-small-font-family: var(--font-family-teko);
    --link-small-text-transform: uppercase;
    --link-small-line-height: 1.428;

    --link-list-color: 0, 0, 0;
    --link-list-font-family: var(--font-family-velo-serif-display);
}

.theme-mh {
    --text-font-family: var(--font-family-lora);
    --text-line-height: 1.625;

    --heading-font-family: var(--font-family-roboto-mono);

    --heading-large-font-family: var(--font-family-knockout);
    --heading-large-font-size: 3.75em;
    --heading-large-font-weight: var(--font-weight-regular);
    --heading-large-line-height: 0.8;

    --heading-medium-font-size: 1.5em;
    --heading-medium-font-weight: var(--font-weight-regular);
    --heading-medium-line-height: 1.166;
    --heading-medium-text-transform: uppercase;

    --heading-small-font-weight: var(--font-weight-regular);
    --heading-small-line-height: 1.333;

    --heading-x-small-font-weight: var(--font-weight-regular);
    --heading-x-small-line-height: 1.285;

    --article-heading-large-font-family: var(--font-family-knockout);
    --article-heading-large-font-size: 3.75em;
    --article-heading-large-font-weight: var(--font-weight-regular);

    --article-heading-medium-font-family: var(--font-family-knockout);
    --article-heading-medium-font-size: 2.5em;
    --article-heading-medium-font-weight: var(--font-weight-regular);

    --article-heading-small-font-family: var(--font-family-work-sans);
    --article-heading-small-font-size: 1.25em;
    --article-heading-small-font-weight: var(--font-weight-semi-bold);
    --article-heading-small-letter-spacing: -0.03em;
    --article-heading-small-line-height: 1.2;

    --paragraph-large-font-family: var(--font-family-work-sans);
    --paragraph-large-font-size: 1.375em;
    --paragraph-large-font-weight: var(--font-weight-regular);
    --paragraph-large-letter-spacing: -0.03em;
    --paragraph-large-line-height: 1.272;

    --caption-medium-font-family: var(--font-family-roboto-mono);
    --caption-medium-font-weight: var(--font-weight-regular);
    --caption-medium-text-transform: initial;

    --caption-small-font-family: var(--font-family-roboto-mono);
    --caption-small-font-weight: var(--font-weight-regular);

    --tag-medium-font-weight: var(--font-weight-regular);
    --tag-medium-letter-spacing: initial;
    --tag-medium-line-height: 1.285;

    --tag-small-font-weight: var(--font-weight-regular);
    --tag-small-letter-spacing: initial;

    --link-medium-font-family: var(--font-family-work-sans);
    --link-medium-font-weight: var(--font-weight-semi-bold);
    --link-medium-letter-spacing: -0.03em;

    --link-small-font-family: var(--font-family-work-sans);
    --link-small-font-weight: var(--font-weight-extra-bold);
    --link-small-letter-spacing: -0.03em;
    --link-small-line-height: 1.428;

    --link-list-color: 0, 0, 0;
    --link-list-font-family: var(--font-family-work-sans);
    --link-list-font-weight: var(--font-weight-semi-bold);
    --link-list-letter-spacing: -0.03em;
}

.theme-wh {
    --text-font-family: var(--font-family-lora);
    --text-line-height: 1.4;

    --heading-font-family: var(--font-family-poppins);

    --heading-medium-line-height: 1.143;

    --heading-small-font-weight: var(--font-weight-regular);
    --heading-small-line-height: 1.333;

    --heading-x-small-font-family: var(--font-family-gazpacho);
    --heading-x-small-line-height: 1.285;
    --heading-x-small-text-transform: initial;

    --article-heading-medium-font-size: 2em;
    --article-heading-medium-line-height: 1.111;

    --article-heading-small-font-family: var(--font-family-apparel);
    --article-heading-small-font-size: 1.75em;
    --article-heading-small-line-height: 1.143;

    --paragraph-large-font-family: var(--font-family-poppins);
    --paragraph-large-font-size: 1.25em;
    --paragraph-large-font-weight: var(--font-weight-regular);
    --paragraph-large-line-height: 1.4;

    --caption-medium-font-family: var(--font-family-gazpacho);
    --caption-medium-text-transform: initial;

    --caption-small-font-family: var(--font-family-poppins);
    --caption-small-font-weight: var(--font-weight-regular);
    --caption-small-text-transform: initial;

    --tag-medium-font-size: 0.875em;
    --tag-medium-font-weight: var(--font-weight-bold);
    --tag-medium-letter-spacing: initial;
    --tag-medium-line-height: 1.285;

    --tag-small-font-weight: var(--font-weight-bold);
    --tag-small-letter-spacing: initial;
    --tag-small-text-transform: initial;

    --link-medium-font-family: var(--font-family-poppins);
    --link-medium-text-transform: uppercase;

    --link-small-font-family: var(--font-family-poppins);
    --link-small-font-weight: var(--font-weight-regular);
    --link-small-text-transform: uppercase;
    --link-small-line-height: 1.428;

    --link-list-color: 0, 0, 0;
    --link-list-font-family: var(--font-family-poppins);
}

.theme-rw {
    --text-font-family: var(--font-family-lora);
    --text-line-height: 1.625;

    --heading-font-family: var(--font-family-futura-now-text);

    --heading-large-font-family: var(--font-family-league-gothic);
    --heading-large-font-size: 3em;
    --heading-large-font-weight: var(--font-weight-regular);
    --heading-large-line-height: 1.166;
    --heading-large-text-transform: uppercase;

    --heading-medium-font-family: var(--font-family-oswald);
    --heading-medium-font-weight: var(--font-weight-regular);
    --heading-medium-line-height: 1.143;
    --heading-medium-text-transform: uppercase;

    --heading-small-font-weight: 800;
    --heading-small-line-height: 1.333;

    --heading-x-small-font-weight: var(--font-weight-medium);
    --heading-x-small-letter-spacing: 0.143em;
    --heading-x-small-line-height: 1.571;

    --article-heading-large-font-weight: 800;

    --article-heading-medium-font-size: 2em;
    --article-heading-medium-font-weight: 800;
    --article-heading-medium-line-height: 1.111;

    --article-heading-small-font-size: 1.5em;
    --article-heading-small-font-weight: 800;
    --article-heading-small-line-height: 1em;

    --paragraph-large-font-family: var(--font-family-futura-now-text);
    --paragraph-large-font-size: 1.25em;
    --paragraph-large-font-weight: 800;
    --paragraph-large-line-height: 1.4;

    --caption-medium-font-family: var(--font-family-futura-now-text);
    --caption-medium-font-weight: var(--font-weight-medium);

    --caption-small-font-family: var(--font-family-futura-now-text);
    --caption-small-font-weight: var(--font-weight-medium);

    --tag-medium-font-size: 0.875em;
    --tag-medium-font-weight: 800;
    --tag-medium-text-transform: initial;

    --tag-small-font-weight: 800;
    --tag-small-text-transform: initial;

    --link-medium-font-family: var(--font-family-futura-now-text);
    --link-medium-font-weight: 800;
    --link-medium-text-transform: uppercase;

    --link-small-font-family: var(--font-family-futura-now-text);
    --link-small-font-weight: var(--font-weight-semi-bold);
    --link-small-line-height: 1.428;
    --link-small-text-transform: uppercase;

    --link-list-color: 0, 0, 0;
    --link-list-font-family: var(--font-family-futura-now-text);
}

.inverted {
    --titleColor: var(--invertedTextColor);
    --textColor: var(--invertedTextColor);
    --heading-large-color: var(--invertedTextColor);
    --heading-medium-color: var(--invertedTextColor);
    --heading-small-color: var(--invertedTextColor);
    --heading-x-small-color: var(--invertedTextColor);
    --article-heading-medium-color: var(--invertedTextColor);
    --article-heading-small-color: var(--invertedTextColor);
    --paragraph-large-color: var(--invertedTextColor);
    --paragraph-medium-color: var(--invertedTextColor);
    --caption-medium-color: var(--invertedTextColor);
    --caption-small-color: var(--invertedTextColor);
    --link-medium-color: var(--invertedTextColor);
    --link-list-color: var(--invertedTextColor);
}
