@use '../mixins';

/* stylelint-disable custom-property-pattern, no-descending-specificity */
.button,
button {
    ---button-font-size: 1.1em;
    ---button-padding: 0.4em 1em 0.5em;
    ---button-font-family: inherit;

    .theme-admin & {
        ---button-font-size: 1em;
        ---button-padding: 0.65em 0.9em;
    }

    display: inline-block;
    border: 2px solid rgb(var(--thirdColor));
    color: rgb(var(--thirdColor));
    background: transparent;
    border-radius: var(--standardRadius);
    text-decoration: none;
    cursor: pointer;
    line-height: 1em;
    box-sizing: border-box;
    transition: 75ms all ease-out;
    font-family: var(---button-font-family);
    font-size: var(---button-font-size);
    font-weight: 700;
    padding: var(---button-padding);

    .theme-vi & {
        ---button-font-size: 1em;
        ---button-padding: 0.52em 0.9em;

        border-radius: 20px;
        border: 2px solid rgb(var(--mainColor));
        color: white;
        background: rgb(var(--mainColor));
    }

    &.positive {
        border-color: rgb(var(--positiveColor));
        color: rgb(var(--positiveColor));
    }

    &.negative {
        border-color: rgb(var(--negativeColor));
        color: rgb(var(--negativeColor));
    }

    &.facebook {
        border-color: rgb(var(--facebookColor));
        color: rgb(var(--facebookColor));
    }

    &.light {
        border-color: white;
        color: white;
    }

    &.filled,
    &.hover,
    &:hover {
        background: rgb(var(--thirdColor));
        color: white;

        &.positive {
            background: rgb(var(--positiveColor));
        }

        &.negative {
            background: rgb(var(--negativeColor));
        }

        &.facebook {
            background: rgb(var(--facebookColor));
        }

        &.apple {
            background: rgb(var(--textColor));
        }

        &.light {
            background: white;
            color: rgb(var(--textColor));
        }

        &.betting {
            background: rgb(var(--bettingColor));
        }

        .theme-vi & {
            border: 2px solid rgb(var(--mainColorLighter));
            color: white;
            background: rgb(var(--mainColorLighter));
            text-decoration: none;
        }
    }

    &.filled {
        &.hover,
        &:hover {
            background: none;
            color: rgb(var(--thirdColor));

            &.positive {
                color: rgb(var(--positiveColor));
            }

            &.negative {
                color: rgb(var(--negativeColor));
            }

            &.facebook {
                color: rgb(var(--facebookColor));
            }

            &.betting {
                color: rgb(var(--bettingColor));
            }

            .theme-vi & {
                border: 2px solid rgb(var(--mainColor));
                color: white;
                background: rgb(var(--mainColor));
                text-decoration: none;
            }
        }
    }

    &.slight-hover {
        background: rgb(var(--background));

        .theme-gp & {
            color: inherit;
            border-color: rgb(var(--lightSectionBorder));
        }

        &.hover,
        &:hover {
            color: rgb(var(--mainColorLighter));
            border-color: rgb(var(--mainColorLighter));

            .theme-gp & {
                border-color: rgb(var(--mainColor));
            }
        }
    }

    &.outlined {
        background-color: inherit;
        color: rgb(var(--mainColorLighter));
        border-color: rgb(var(--mainColorLighter));

        .theme-vi & {
            background-color: inherit;
        }

        &.hover,
        &:hover {
            color: inherit;
            border-color: rgb(var(--mainColor));
            background-color: inherit;

            .theme-vi & {
                background-color: inherit;
                border: none;
                color: rgb(var(--secondaryTextColor));
                text-decoration: underline;
            }
        }
    }

    &:disabled,
    &.disabled {
        color: white !important;
        border-color: rgb(var(--sectionSeparationBorder)) !important;
        background: rgb(var(--sectionSeparationBorder)) !important;
        cursor: not-allowed;

        .theme-gp & {
            border-color: rgb(var(--lighterTextColor)) !important;
            background: rgb(var(--lighterTextColor)) !important;
        }
    }

    &.hidden {
        display: none;
    }

    .f-icon {
        &:last-child {
            margin-left: 1em;
            margin-right: -0.3em;
            vertical-align: top;
        }

        &:only-child {
            margin: -0.2em -0.5em;
            font-size: 1.5em;
            vertical-align: bottom;
        }
    }

    .f-logo {
        &:first-child {
            float: left;
        }
    }

    .Icon {
        margin: 0 -0.25em;
    }

    &.linkStyling {
        @extend %link-list;
        padding: var(--standardPadding) 0;
        width: 100%;
        border: none;
        text-align: center;
        font-size: 0.875em;
        font-weight: var(--font-weight-regular);
        color: rgb(var(--mainColorDarker));

        &:hover {
            color: rgb(var(--linkColor));
            background-color: rgba(var(--background), 0);
        }

        &.last-position {
            border-bottom: 0.125rem solid rgb(var(--sectionBorder));
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.inverted {
    .button,
    button {
        &.filled {
            border-width: 1px;
            background: rgb(var(--background));
            border-color: rgb(var(--background));
            color: rgb(var(--mainColor));

            .theme-gp & {
                background: rgb(var(--thirdColor));
                border-color: rgb(var(--thirdColor));
                color: rgb(var(--background));
            }

            &:hover,
            .hover {
                background: none;
                border-color: rgb(var(--invertedTextColor));
                color: rgb(var(--invertedTextColor));
            }
        }
    }
}
/* stylelint-enable custom-property-pattern, no-descending-specificity */
