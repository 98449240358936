@use 'sass:color';
@use 'sass:list';
@use 'sass:math';

/* stylelint-disable scss/no-global-function-names, scss/at-mixin-pattern */
@mixin transition($properties: all, $speed: 200ms, $ease: ease-out, $delay: 0ms) {
    $transition: ();

    @each $property in $properties {
        $trans: ($property $speed $ease $delay);

        @if type-of($property) == list {
            $trans: $property;
        }

        $transition: append($transition, $trans, comma);
    }

    transition: $transition;
}

@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &.placeholder-style {
        @content;
    }
}

@mixin input-autofill-style($textColor, $backgroundColor) {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $backgroundColor inset;
        -webkit-text-fill-color: $textColor;
    }

    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px $backgroundColor inset;
        -webkit-text-fill-color: $textColor;
    }
}

@mixin selection-color($colour) {
    &::selection,
    ::selection {
        background: $colour;
    }

    &::-moz-selection,
    ::-moz-selection {
        background: $colour;
    }
}

%disabled {
    opacity: 0.5;
    cursor: default;
    text-decoration: none !important;
    pointer-events: none !important;

    * {
        pointer-events: none !important;
    }
}

@mixin hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }

    @return $number;
}

@function encodecolor($string) {
    @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote('#{$hex}');

        @return '%23' + $string;
    }

    @return $string;
}

@mixin horizontalScrollSnap {
    position: relative;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    list-style: none;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center;

    &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
    }
}

@mixin horizontalScrollSnapOneElement {
    // Reuse the funcion created above
    @include horizontalScrollSnap;

    white-space: nowrap;

    > * {
        height: 100%;
        min-width: 100%;
        scroll-snap-align: center;
    }
}

@function rgb-list-to-color($rgb-list) {
    @return rgb(list.nth($rgb-list, 1), list.nth($rgb-list, 2), list.nth($rgb-list, 3));
}

@function color-to-rgb-list($rgb) {
    @return color.red($rgb), color.green($rgb), color.blue($rgb);
}

%no-link-styles {
    display: contents;
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

@mixin row-limit($limit) {
    display: -webkit-box;
    -webkit-line-clamp: $limit;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

%card-styles {
    display: inline-block;
    padding: var(--standardPadding);
    background-color: rgb(var(--lightSectionBack));
    list-style-type: none;
    text-align: left;
    vertical-align: center;
    border: 0;
    border-bottom: 1px solid rgb(var(--lightSectionBorder));
    border-radius: var(--standardRadius);
}
/* stylelint-enable scss/no-global-function-names, scss/at-mixin-pattern */
