@use 'mixins';

$iconWidth: 1em;
$iconHeight: 0.75em;
$stripeHeight: 2px;
$stripesSpacing: 3px;

.HamburgerIcon {
    display: inline-block;
    width: $iconWidth;
    height: $iconHeight;
    padding: 0;
    margin-left: var(--standardPadding);
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    .first-stripe,
    .second-stripe,
    .third-stripe {
        display: block;
        position: absolute;
        height: $stripeHeight;
        width: $iconWidth;
        left: 0;
        background: rgb(var(--textColor));
        border-radius: var(--standardRadius);
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        cursor: inherit;

        &:hover {
            background: rgb(var(--lighterTextColor));
        }
    }

    .first-stripe {
        top: 0;
    }

    .second-stripe {
        top: calc($stripeHeight + $stripesSpacing);
    }

    .third-stripe {
        top: calc(2 * ($stripeHeight + $stripesSpacing));
    }

    &.inverted {
        .first-stripe,
        .second-stripe,
        .third-stripe {
            background: rgb(var(--invertedTextColor));
        }
    }

    &.is-open {
        .first-stripe {
            top: calc($iconHeight / 2);
            left: 1px;
            transform: rotate(45deg);
        }

        .second-stripe {
            opacity: 0;
            width: 0;
        }

        .third-stripe {
            top: calc($iconHeight / 2);
            left: 1px;
            transform: rotate(-45deg);
        }
    }
}
