@use 'mixins';

.UserArea {
    display: inline-flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    order: 2;
    flex: 1;
    max-width: calc(100% / 3);

    img {
        margin-right: 0.75em;
    }

    :global(.theme-vi) & {
        max-width: unset;
        margin-right: var(--standardPadding);

        :global(.Icon) {
            margin-right: var(--smallPadding);
        }
    }

    :global(.Icon) {
        margin-right: 0.75em;
        font-size: 1.5em;
        color: rgba(var(--invertedTextColor, 0.2));
        opacity: 0.5;
    }

    @include mixins.responsive(l, above) {
        display: flex;
        flex-wrap: nowrap;
        order: 3;
        max-width: max-content;
        padding-left: var(--standardPadding);
        margin-left: var(--standardPadding);

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 2px;
            height: 2em;
            background-color: rgba(var(--sectionBorder), 0.2);
            transform: translateY(-50%);
        }

        :global(.theme-br) &::before {
            display: none;
        }
    }
}
