.PlatformLogo {
    display: block;
    position: relative;
    width: auto;
    max-height: 100%;

    svg {
        width: auto;
    }

    &.header,
    &.footer {
        svg {
            height: 2.5em;

            :global(.theme-br) & {
                height: 2em;
            }

            :global(.theme-vp) & {
                height: 2.25em;
            }

            :global(.theme-vi) & {
                height: 3em;
            }

            :global(.theme-gp) & {
                height: 3em;
            }
        }
    }

    &.footer-mini {
        display: inline-block;

        svg {
            height: 1em;
        }

        svg path {
            fill: rgb(var(--lighterTextColor));
        }
    }

    &.extra-padding {
        margin-bottom: var(--standardPadding);
    }

    &.has-background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4em;
        height: 4em;
        border-radius: var(--standardRadius);
        background-color: rgb(var(--mainColor));

        :global(.theme-vi) & {
            border: 1px solid rgb(var(--sectionBorder));
            background-color: rgb(var(--background));
            border-radius: var(--tinyPadding);
        }

        svg {
            height: 1.375em;
        }
    }
}
