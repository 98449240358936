@use 'mixins';

.MainDropdownContainer {
    @extend %tag-large;

    margin-left: auto;
    position: relative;
    max-width: min-content;
    font-size: 1em;
    line-height: 1.5;

    :global(.theme-vn) &,
    :global(.theme-vp) &,
    :global(.theme-vpbe) & {
        color: rgb(var(--lighterTextColor));
    }

    .trigger {
        display: flex;
        align-items: center;
        width: max-content;
        margin: var(--smallPadding);

        svg {
            display: flex;
            width: 0.75em;
            height: 1em;
            transition: 0.25s ease-in-out;

            > :global(path) {
                fill: rgb(var(--thirdColor));

                :global(.theme-gp) & {
                    fill: rgb(var(--lighterTextColor));
                }
            }
        }

        .arrow {
            display: flex;
            align-items: center;
        }

        .text {
            margin-right: var(--tinyPadding);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .MainDropdown {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        position: absolute;
        z-index: 5;
        right: 0;
        visibility: hidden;
        opacity: 0;
        overflow: hidden scroll;
        max-height: 20vh;
        transition:
            opacity 0.15s ease,
            transform 0.15s ease,
            visibility 0.15s;
        transition-delay: 0.15s;
        background-color: rgb(var(--background));
        border: 1px solid rgb(var(--sectionBorder));
        border-radius: var(--standardRadius);

        &.active {
            visibility: visible;
            opacity: 1;
            height: auto;
        }

        .list-item {
            width: max-content;
            min-width: calc(12.5em - var(--standardPadding));
            padding: var(--standardPadding);
            padding-right: 0;
            transition: color 0.15s ease-out;
            cursor: pointer;
            border-bottom: 1px solid rgb(var(--sectionBorder));
            text-transform: capitalize;

            :global(.theme-vi) & {
                padding-right: var(--standardPadding);
            }

            &:hover {
                transition: color 0.15s ease-in;
                color: rgb(var(--mainColor));
            }

            &.selected {
                color: rgb(var(--mainColor));
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    &.centered {
        margin: auto;
        background-color: rgb(var(--background));
        border-radius: var(--standardRadius);

        .trigger {
            width: auto;
            justify-content: flex-end;
        }
    }

    &.expanded .arrow svg {
        transform: scaleY(-1);
    }

    &.inverted {
        .MainDropdown {
            border: 0;
        }

        .trigger {
            padding: var(--smallPadding);
            background-color: rgb(var(--invertedBackground));
            border: 1px solid rgb(var(--sectionBorder), 0.2);
            border-radius: var(--hugePadding);

            svg {
                width: 1em;
            }

            :global(.Icon) {
                margin-bottom: 2px;
                font-size: 1.5em;
                line-height: 1.5em;
                color: rgb(var(--invertedTextColor));
                text-align: center;
            }
        }

        .list-item {
            background-color: rgb(var(--invertedBackground));
            color: rgb(var(--invertedTextColor));
        }
    }

    &.expand-to-top {
        .MainDropdown {
            bottom: 3.5em;
            left: var(--smallPadding);
            right: unset;
            border: 1px solid rgb(var(--sectionBorder));

            li {
                background-color: rgb(var(--background));
                color: rgb(var(--textColor));
            }
        }

        .arrow svg {
            transform: scaleY(-1);
        }

        &.expanded .arrow svg {
            transform: scaleY(1);
        }

        :global(.Icon) {
            margin-top: var(--tinyPadding);
        }
    }
}
