@use 'mixins';

body,
html {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: rgb(var(--background));
    font-family: var(--text-font-family);
    font-weight: var(--font-weight-regular);
    color: rgb(var(--textColor));
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
        env(safe-area-inset-left);
    color: rgb(var(--textColor));

    > ins[id^='gpt_'] {
        z-index: 240 !important;
    }
}

body > #__next {
    display: flex;
    flex-direction: column;
}

main {
    padding-top: var(--hugePadding);

    &.header-page-wrapper {
        background-color: rgb(var(--alternativeBackground));
    }

    .profile-page-content {
        display: flex;
        justify-content: space-evenly;
    }
}

.inverted {
    color: rgb(var(--invertedTextColor));
}

@include mixins.responsive(m, below) {
    main {
        padding-top: 0;

        .profile-page-content {
            flex-direction: column;
        }
    }
}
