@use 'mixins';

.tag {
    @extend %tag-small;

    display: inline-block;
    padding: 0 0.37em;
    background: rgb(var(--mainColor));
    color: rgb(var(--invertedTextColor));
    vertical-align: text-bottom;
    margin-right: 0.1em;
    white-space: nowrap;

    .theme-br & {
        width: fit-content;
        border-radius: 0;
    }

    .theme-admin & {
        text-transform: none;
        background: rgb(var(--lightSectionBorder));
        color: rgb(var(--titleColor));
        padding: 0.35em 0.4em 0.2em;
        font-size: 0.95em;
        font-weight: 500;
    }

    .Icon {
        margin-top: -0.1em;
        margin-right: var(--tinyPadding);

        /* stylelint-disable-next-line selector-class-pattern */
        &.iconVideo-icon {
            font-size: 2em;
            margin: -0.25em -0.1em -0.25em -0.25em;
        }
    }

    &.medium {
        @extend %tag-medium;
        padding: 0 1em;
        background-color: rgb(var(--lightSectionBack));
        border: none;
        &:hover {
            background-color: rgb(var(--alternativeBackground));
            color: initial;
        }
    }

    &.large {
        @extend %tag-large;
        color: rgb(var(--invertedTextColor));
        padding: 0.6em;
    }

    &.circular {
        border-radius: 1em;
        min-width: 1em;
        text-align: center;
    }

    &.counter {
        margin: 0;
        padding: 0;
        line-height: 1.35em;
        border-radius: 0;
        background-color: rgb(var(--colorGray));
        color: rgb(var(--invertedBackground));
        min-width: 1.35em;
        height: 1.35em;
        text-align: center;

        &.highlighted {
            color: rgb(var(--invertedTextColor));
            background-color: rgb(var(--mainColor));
        }
    }

    &.video,
    &.podcast {
        background-color: rgb(var(--mediaColor));
    }

    &.breaking {
        background-color: rgb(var(--breakingColor));
    }

    &.success {
        background-color: rgb(var(--successColor));
    }

    &.live {
        background-color: transparent;
        color: rgb(var(--negativeColor));
        border: 1px solid rgb(var(--negativeColor));
        font-weight: 700;

        @include mixins.pulse-red;
    }

    &.positive {
        background-color: rgb(var(--positiveColor));
    }

    &.negative {
        background-color: rgb(var(--negativeColor));
    }

    &.neutral {
        background-color: rgb(var(--sectionSeparationBorder));
    }

    &.advertorial {
        background-color: rgb(var(--sectionSeparationBorder));

        .theme-br & {
            background-color: rgb(var(--fifthColor));
            color: rgb(var(--invertedBackground));
        }
    }

    &.exclusive {
        background-color: rgb(var(--secondColor));
    }

    &.exclusive-news {
        background-color: rgb(var(--sixthColor));
    }

    &.rumor {
        background-color: rgb(var(--fifthColor));
        color: rgb(var(--invertedBackground));
    }

    &.interview {
        background-color: rgb(var(--invertedBackground));
    }

    &.column {
        background-color: rgb(var(--invertedBackground));
    }

    &.betting {
        background-color: rgb(var(--bettingColor));
    }

    &.color-green {
        background-color: rgb(var(--colorGreen));
    }

    &.color-light-green {
        background-color: rgb(var(--colorGreen));
        opacity: 0.5;
    }

    &.color-blue {
        background-color: rgb(var(--colorBlue));
    }

    &.color-light-blue {
        background-color: rgb(var(--colorBlue));
        opacity: 0.5;
    }

    &.color-orange {
        background-color: rgb(var(--colorOrange));
    }

    &.color-red {
        background-color: rgb(var(--colorRed));
    }

    &.color-black {
        background-color: rgb(var(--invertedBackground));
        color: rgb(var(--invertedTextColor));
    }
}

a.tag {
    background-color: rgb(var(--linkColor));
    border: 1px solid rgb(var(--linkColor));
    text-decoration: none;

    &:hover {
        color: rgb(var(--linkColor));
        background: transparent;
    }
}
