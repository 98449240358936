@use 'mixins';

.LanguageSelector {
    .header {
        width: 3.25em;
        margin-right: var(--smallPadding);
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgb(var(--invertedTextColor));

        :global(.Icon) {
            font-size: 1.5em !important;
        }
    }
}
