@mixin pulse-red {
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgb(var(--negativeColor));
    }

    70% {
        transform: scale(0.8);
        box-shadow: 0 0 0 5px rgba(var(--negativeColor), 0);
    }

    100% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(var(--negativeColor), 0);
    }
}

@mixin fade-in {
    animation: fade-in 0.7s ease-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }

    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}

@mixin fade-out {
    animation: fade-out 0.7s ease-out;
}

@keyframes fade-out {
    0% {
        opacity: 1;
        transform: scaleY(1);
    }

    100% {
        opacity: 0;
        transform: scaleY(0);
    }
}

@mixin slide-in-from-top {
    animation: slide-in-from-top 0.8s ease-in-out forwards;
}

@keyframes slide-in-from-top {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

// Use to add smooth transition to forms
@mixin fade-in-form {
    animation: fade-in-form 0.5s ease-out forwards;
}

@keyframes fade-in-form {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.form-animation {
    animation: fade-in-form 0.5s ease-out forwards;
}

@mixin backdrop-fade-in {
    animation: 0.7s ease-out forwards;
}

@keyframes backdrop-fade-in {
    0% {
        background-color: rgb(var(--invertedBackground));
    }

    100% {
        background-color: rgba(var(--invertedBackground), 0.5);
    }
}
